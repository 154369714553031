import {ButtonColor} from '@common/ui/buttons/get-shared-button-style';
import {useSettings} from '@common/core/settings/use-settings';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import {Menu, MenuTrigger} from '@common/ui/navigation/menu/menu-trigger';
import {IconButton} from '@common/ui/buttons/icon-button';
import {PersonIcon} from '@common/icons/material/Person';
import {Item} from '@common/ui/forms/listbox/item';
import {Trans} from '@common/i18n/trans';
import {Link} from 'react-router-dom';
import {Button} from '@common/ui/buttons/button';
import {NavbarProps} from '@common/ui/navigation/navbar/navbar';
import {Fragment} from 'react';
import {apiClient, queryClient} from '@common/http/query-client';
import {showHttpErrorToast} from '@common/utils/http/show-http-error-toast';
import {toast} from '@common/ui/toast/toast';
import { useAuth } from '@common/auth/use-auth';
import { useBootstrapData } from '@common/core/bootstrap-data/bootstrap-data-context';

// import { useNavigate, Navigate } from 'react-router-dom';
interface NavbarAuthButtonsProps {
  primaryButtonColor?: ButtonColor;
  navbarColor?: NavbarProps['color'];
}
export function NavbarAuthButtons({
  primaryButtonColor,
  navbarColor,
}: NavbarAuthButtonsProps) {
  if (!primaryButtonColor) {
    primaryButtonColor = navbarColor === 'primary' ? 'paper' : 'primary';
  }

  return (
    <Fragment>
      <MobileButtons />
      <DesktopButtons primaryButtonColor={primaryButtonColor} />
    </Fragment>
  );
}

interface DesktopButtonsProps {
  primaryButtonColor: ButtonColor;
}

function DesktopButtons({primaryButtonColor}: DesktopButtonsProps) {
const {registration} = useSettings();
const navigate = useNavigate();

const {getRedirectUri} = useAuth();
const {setBootstrapData} = useBootstrapData();
const fetchLoginValues = async (): Promise<any> => {
  try {
    const response = await apiClient.post('ssologin');
    if (response.data.redirect_url) {
      setBootstrapData(response.data.bootstrapData);
      navigate(getRedirectUri(), {replace: true});
    }
  } catch (error:any) {
    toast.danger(error?.response?.data?.message)
    navigate('/');
  }
};

  return (
    <div className="text-sm max-md:hidden">
      {/* {!registration.disable && (
        <Button
          elementType={Link}
          to="/register"
          variant="text"
          className="mr-10"
        >
          <Trans message="Register" />
        </Button>
      )} */}
      <Button
        variant="raised"
        color={primaryButtonColor}
        onClick={()=>fetchLoginValues()}
      >
        <Trans message="Login" />
      </Button>
    </div>
  );
}

function MobileButtons() {
  const {registration} = useSettings();
  const navigate = useNavigate();

  const {getRedirectUri} = useAuth();
  const {setBootstrapData} = useBootstrapData();
  const fetchLoginValues = async (): Promise<any> => {
    try {
      const response = await apiClient.post('ssologin');
      if (response.data.redirect_url) {
        setBootstrapData(response.data.bootstrapData);
        navigate(getRedirectUri(), {replace: true});
      }
    } catch (error:any) {
      toast.danger(error?.response?.data?.message)
      navigate('/');
    }
  };
  
  return (
    <MenuTrigger>
      <IconButton size="md" className="md:hidden">
        <PersonIcon />
      </IconButton>
      <Menu>
        <Item value="login" onClick={()=>fetchLoginValues()}>
          <Trans message="Login" />
        </Item>
        
      </Menu>
    </MenuTrigger>
  );
}
